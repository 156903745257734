<template>
  <div>

    <loading :loading="isLoading"></loading>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <div class="img logo purple"></div>
              </div>

              <StepProgress :steps="3" :current="currentStep" />

              <!--begin::Form-->
              <b-form class='form mt-3' @submit.stop.prevent='nextStep'>

                <div v-if="currentStep === 0">
                  <div
                    role='alert'
                    v-bind:class='{ show: errors.length }'
                    v-if="errors.length > 0"
                    class='alert fade alert-danger'
                  >
                    <div class='alert-text' v-for='(error, i) in errors' :key='i'>
                      {{ error }}
                    </div>
                  </div>

                  <b-form-group
                    id='input-group-name'
                    :label="$t('FORMS.name')"
                    label-for='input-name'
                  >
                    <b-form-input
                      class='form-control form-control-solid h-auto px-6'
                      id='input-name'
                      name='input-name'
                      v-model='$v.form.name.$model'
                      autocomplete="name"
                      :state="isFormFieldValid('name')"
                      aria-describedby='input-name-live-feedback'
                    ></b-form-input>

                    <b-form-invalid-feedback id='input-name-live-feedback'>
                      {{ $t('FORMS.ERRORS.name_length') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id='input-group-email'
                    :label="$t('FORMS.email')"
                    label-for='example-input-1'
                  >
                    <b-form-input
                      class='form-control form-control-solid h-auto px-6'
                      id='input-email'
                      name='input-email'
                      v-model='$v.form.email.$model'
                      autocomplete="username"
                      :state="isFormFieldValid('email')"
                      aria-describedby='input-email-live-feedback'
                    ></b-form-input>

                    <b-form-invalid-feedback id='input-email-live-feedback'>
                      <!-- TODO Translate the error -->
                      <div v-if="!$v.form.email.serverSaidOK">{{ serverErrors.email }}</div>
                      <div v-if="!$v.form.email.required">{{ $t('AUTH.email_required') }}</div>

                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id='token-group'
                    :label="$t('AUTH.signup_token')"
                    label-for='token'

                    v-if="hasSignUpToken"
                  >
                    <b-form-input
                      class='form-control form-control-solid h-auto px-6'
                      id='token'
                      name='token'
                      v-model='$v.form.token.$model'
                      autocomplete=""
                      :state="isFormFieldValid('token')"
                      aria-describedby='token-live-feedback'
                    ></b-form-input>

                    <b-form-invalid-feedback id='token-live-feedback'>
                      <!-- TODO Translate the error -->
                      <div v-if="!$v.form.token.serverSaidOK">{{ serverErrors.token || serverErrors.subClient }}</div>
                      <div v-if="!$v.form.token.required">{{ $t('FORMS.required') }}</div>
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <!--begin::Action-->
                  <div
                    class='d-flex flex-wrap justify-content-between align-items-center text-center'
                  >

                    <button
                      ref='kt_login_signin_submit'
                      class='btn btn-primary font-weight-bold px-9 my-3 font-size-3'
                      style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                    >
                      {{ $t('ACTIONS.continue') }}
                    </button>
                  </div>
                  <!--end::Action-->
                </div>

                <div v-if="currentStep === 1">
                  <p class="paragraph">{{ $t('AUTH.select_certificacions_description') }}.</p>

                  <b-form-textarea
                    id="textarea"
                    v-model='$v.form.plainCertifications.$model'
                    :placeholder="$t('AUTH.extra_certificacions_placeholder')"
                    rows="3"
                    max-rows="6"
                    :state="isFormFieldValid('plainCertifications')"
                    aria-describedby='textarea-live-feedback'
                  ></b-form-textarea>

                  <b-form-invalid-feedback id='textarea-live-feedback'>
                    <!-- TODO Translate the error -->
                    <!-- <div v-if="!$v.form.plainCertifications.serverSaidOK">{{ serverErrors.plainCertifications }}</div> -->
                    <div v-if="!$v.form.plainCertifications.required">{{ $t('FORMS.required') }}</div>

                  </b-form-invalid-feedback>

                  <!--begin::Action-->
                  <div
                    class='d-flex flex-wrap justify-content-between align-items-center text-center'
                  >

                    <b-button
                      ref='kt_login_signin_submit'
                      class='btn btn-primary font-weight-bold px-9 mt-4 mb-2 font-size-3'
                      style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                      variant="primary"
                      block
                      @click="nextStep"
                    >
                      {{ $t('ACTIONS.continue') }}
                    </b-button>
                    <b-button
                      ref='kt_login_signin_submit'
                      class='btn btn-primary font-weight-bold px-9 mb-3 font-size-3'
                      style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                      variant="secondary"
                      block
                      @click.stop.prevent="currentStep -= 1"
                    >
                      {{ $t('ACTIONS.back') }}
                    </b-button>
                  </div>
                  <!--end::Action-->
                </div>
              </b-form>
                <router-link
                  :to="{ name: 'login' }"
                  class='text-dark-60 text-hover-primary mr-2 w100p'
                  style="display: block; font-size: small; text-align: center;"
                >
                {{ $t('AUTH.already_registered') }}
                </router-link>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}
</style>

<script>
import ApiService from '@/services/api.service'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import URLS from '@/config/urls'

import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'

import StepProgress from '@/components/utils/StepProgress'

import ErrorsMixin from '@/services/mixins/errors.mixins'
import ToastsMixin from '@/services/mixins/toasts.mixins'

export default {
  mixins: [ToastsMixin, validationMixin, ErrorsMixin],
  components: { StepProgress },
  name: 'Register',
  data () {
    return {
      currentStep: 0,
      form: {
        name: '',
        email: '',
        token: '',
        plainCertifications: ''
      },
      serverErrors: {
        name: '',
        username: '',
        email: '',
        token: '',
        subClient: '',
        plainCertifications: ''
      }
    }
  },
  validations () {
    const validationObj = {
      form: {
        name: {
          required,
          minLength: minLength(3)
        },
        email: {
          required,
          email,
          serverSaidOK (value) {
            return this.serverErrors.email === ''
          }
        },
        plainCertifications: {
          serverSaidOK (value) {
            return this.serverErrors.plainCertifications === ''
          }
        }
      }
    }

    if (this.hasSignUpToken) {
      validationObj.form.token = {
        required,
        serverSaidOK (value) {
          return this.serverErrors.token === '' && this.serverErrors.subClient === ''
        }
      }
    }

    return validationObj
  },
  watch: {
    formEmail () {
      this.serverErrors.email = ''
    },
    formToken () {
      this.serverErrors.token = ''
      this.serverErrors.subClient = ''
    },
    formPlainCertifications () {
      this.serverErrors.plainCertifications = ''
    }
  },
  methods: {
    ...mapActions({
      submitSignUp: 'auth/signUp',
      getAvailableCertificates: 'user/getAvailableCertificates'
    }),
    ...mapMutations({
      setCsrfToken: 'auth/setCsrfToken'
    }),
    isFormFieldValid (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    isFirstStepValid () {
      let isValid = this.isFormFieldValid('name') && this.isFormFieldValid('email')

      if (this.hasSignUpToken) {
        isValid = isValid && this.isFormFieldValid('token')
      }
      return isValid
    },
    isFormValid () { return !this.$v.form.$anyError },
    touchFirstStep () {
      this.$v.form.name.$touch()
      this.$v.form.email.$touch()

      if (this.hasSignUpToken) {
        this.$v.form.token.$touch()
      }
    },
    resetForm () {
      this.form = {
        name: null,
        email: null,
        plainCertifications: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    nextStep () {
      if (this.currentStep === 1) {
        this.$v.form.$touch()
        if (!this.isFormValid()) return

        this.onSubmit()
      } else {
        this.touchFirstStep()
        if (this.currentStep === 0 && !this.isFirstStepValid()) return

        this.currentStep += 1
      }
    },
    mapErrors (errorsObj) {
      Object.keys(errorsObj).forEach(err => {
        if (Array.isArray(errorsObj[err])) {
          this.serverErrors[err] = this.mapBackendRegisterError(errorsObj[err][0])
        }
      })
    },
    onSubmit () {
      const params = {
        name: this.$v.form.name.$model,
        username: this.$v.form.email.$model,
        email: this.$v.form.email.$model,

        // Token, only if needed
        token: this.hasSignUpToken ? this.$v.form.token.$model : undefined,

        // Certification data
        plainCertifications: this.$v.form.plainCertifications.$model
      }

      this.submitSignUp(params)
        .then(response => {
          if (response.status === 200 && response.data.status === 'error') throw response

          if (response.data.status === 'ok') {
            this.$router.push({ name: 'confirmAccount' })
          } else {
            this.showErrorToast(this.$t('AUTH.sign_up_error'))
          }
        })
        .catch(response => {
          if (typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
            this.mapErrors(response.data.data)
          }

          if (typeof response.data !== 'undefined' && typeof response.data.message !== 'undefined') {
            this.showErrorToast(this.mapBackendRegisterError(response.data.message))
          } else {
            this.showErrorToast(this.$t('AUTH.sign_up_error'))
          }

          // Move to first step if necessary
          if (this.serverErrors.email !== '' || this.serverErrors.name !== '' || this.serverErrors.token !== '' || this.serverErrors.subClient !== '') this.currentStep = 0
        })
    }
  },
  computed: {
    ...mapGetters({
      authConfig: 'config/getAuthConfig',
      isAuthenticated: 'auth/isAuthenticated',
      isLoading: 'auth/isLoading',
      errors: 'auth/getErrors',
      getCsrfToken: 'auth/getCsrfToken'
    }),
    hasSignUpToken () { return this.authConfig.useSignUpToken },
    formEmail () { return this.form.email },
    formToken () { return this.form.token },
    formPlainCertifications () { return this.form.plainCertifications }
  },

  mounted () {
    ApiService.queryWithConfig(URLS.API.STATIC.CUSTOM_CSS, {}, { withCredentials: true })
      .then(response => {
        if (typeof response.headers !== 'undefined' && typeof response.headers['x-request-intent'] !== 'undefined') {
          this.setCsrfToken(response.headers['x-request-intent'])
        }
      })
  }
}
</script>
