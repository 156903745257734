<template>
  <div
    class="step-progress"
    :style="{ '--progress': `${current * spaceBetween}%` }"
    >
    <div
     v-for="(step) in stepsArray"
     v-bind:key="step"
     class="step-wrapper"
     >
      <span class="step" :class="{ past: step < current }" v-if="step >= current">{{ step + 1 }}</span>
      <span class="step active" v-else>
        <b-icon icon="check2" variant="white" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: { required: true },
    current: { required: true }
  },
  computed: {
    stepsArray () { return Array.from(Array(this.steps).keys()) },
    spaceBetween () { return 100 / (this.stepsArray.length - 1) }
  }
}
</script>

<style lang="scss" scoped>

.step-progress {
  position: relative;
  z-index: auto;
  display: flex;
  justify-content: between;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    height: 6px;
    background: var(--primary);
    background: linear-gradient(90deg, var(--secondary) var(--progress), #D8D8D8 var(--progress));
  }

  .step-wrapper {
    text-align: center;
    flex: 1;
    z-index: 1;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    .step {
      display: inline-block;
      text-align:center;
      width: 2rem;
      border: 2px solid var(--primary);
      background: white;
      padding: 4px;

      &.past, &.active {
        background: var(--primary);
        color: var(--primary-inverse);
      }
    }
  }
}

</style>
